<script lang="ts" setup>
import type { Voucher } from '~/types/api'

const modal = useModal()
const { voucher } = defineProps<{
  voucher: Voucher
}>()
</script>

<template>
  <UModal>
    <UCard :ui="{ footer: { padding: 'pt-0' } }">
      <i18n-t
        keypath="notification.voucher-applied"
        tag="p"
        :plural="voucher.min_value"
      >
        <template #code>
          <b>{{ voucher.code }}</b>
        </template>
        <template #min_value>
          <b>{{ $n(voucher.min_value, 'currency') }}</b>
        </template>
        <template #value>
          <b>{{ $n(voucher.value, 'currency') }}</b>
        </template>
      </i18n-t>
      <template #footer>
        <div class="text-right">
          <UButton class="px-3" @click="modal.close()">Ok</UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
